import React, { useState } from 'react';
import {
  Box, Select, MenuItem, FormControl, InputLabel, Typography
} from '@mui/material';
import SalesReport from './SalesReport';
import MonthlySalesReport from './MonthlySalesReport';
import OrdersByMonthReport from './OrdersByMonthReport';
import SalesByMachineReport from './SalesByMachineReport';
import OrdersWrittenReport from './OrdersWrittenReport';
import SalesByCustomerReport from './SalesByCustomerReport';
import ReceivablesReport from './ReceivablesReport';

const Sales = () => {
  const [report, setReport] = useState('');

  const handleReportChange = (event) => {
    setReport(event.target.value);
  };

  const renderReport = () => {
    if (report === '') {
      return (
        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <Typography variant="h6" color="textSecondary">
            Please select a report to display.
          </Typography>
        </Box>
      );
    }

    switch (report) {
      case 0:
        return <SalesByCustomerReport />;
      case 1:
        return <MonthlySalesReport />;
      case 2:
        return <OrdersByMonthReport />;
      case 3: 
        return <SalesByMachineReport />;
      case 4: 
        return <OrdersWrittenReport />;
      case 5:
        return <ReceivablesReport />;
      default:
        return (
          <Box sx={{ textAlign: 'center', mt: 6 }}>
            <Typography variant="h6" color="textSecondary">
              No valid report selected.
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Box sx={{maxWidth: '1200px', mx: 'auto', m: 2, }}>
      <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
        <InputLabel id="sales-report-select-label">Select Report</InputLabel>
        <Select
          labelId="sales-report-select-label"
          value={report}
          onChange={handleReportChange}
          label="Select Report"
        >
          <MenuItem value={0}>Sales By Customer</MenuItem>
          <MenuItem value={1}>Monthly Sales</MenuItem>
          <MenuItem value={2}>Orders By Month</MenuItem>
          <MenuItem value={3}>Sales By Machine</MenuItem>
          <MenuItem value={4}>Orders Written</MenuItem>
          <MenuItem value={5}>Receivables</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ width: '100%', mt: 1 }}>
        {renderReport()}
      </Box>
    </Box>
  );
};

export default Sales;
