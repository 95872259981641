import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import EmployeeStore from '../stores/EmployeeStore';
import DynamicSearchFilter from '../components/DynamicSearchFilter';
import {
  Box, Typography, List, ListItem, ListItemText, Snackbar, IconButton,
  TablePagination, useMediaQuery, Avatar, Badge, Chip, ListItemAvatar, Button, ToggleButton, ToggleButtonGroup
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';
import Checkbox from '@mui/material/Checkbox';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Employees = observer(() => {
  const [employees, setEmployees] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [selectedRows, setSelectedRows] = useState([]);
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [filterType, setFilterType] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});
  const [sortBy, setSortBy] = useState('firstName');
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));

  useEffect(() => {
    fetchPaginatedEmployees();
  }, [filterType, sortBy]);

  const fetchPaginatedEmployees = async (page = 1, pageSize = 10) => {
    setLoading(true);

    let localFilter = { ...filterCriteria };
    if (filterType === 'active') {
      localFilter.finalDate = { $eq: null };
    } else if (filterType === 'terminated') {
      localFilter.finalDate = { $ne: null };
    }

    localFilter.firstName = {
      $ne: "Unidentified"
    };

    EmployeeStore.fetchPaginatedEmployees(page, pageSize, sortBy, localFilter, searchQuery).then(() => {
      setEmployees(EmployeeStore.paginatedEmployees);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch employees', severity: 'error' });
      setLoading(false);
    });
  };

  const handleViewEmployee = (employeeId) => {
    navigate(`/employee/${employeeId}`);
  };

  const handleEditEmployee = (employeeId) => {
    navigate(`/employee/edit/${employeeId}`);
  };

  const handleDeleteEmployee = async () => {
    if (selectedRows.length > 0) {
      try {
        await Promise.all(selectedRows.map(employeeId => EmployeeStore.deleteEmployee(employeeId)));
        setSnackbar({ open: true, message: 'Employee(s) deleted successfully', severity: 'success' });
        fetchPaginatedEmployees();
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to delete employee(s)', severity: 'error' });
      }
    }
  };

  const handleCreateEmployee = () => {
    navigate('/employee/add');
  };

  const handleShowPersonalInfo = () => {
    setShowPersonalInfo(!showPersonalInfo);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, page) => {
    fetchPaginatedEmployees(page + 1, employees.pageSize);
  };

  const handleLimitChange = (event) => {
    fetchPaginatedEmployees(1, event.target.value);
  };

  const handleDynamicSearch = (search, filter, sortBy) => {
    setSearchQuery(search);
    setFilterCriteria(filter);
    setSortBy(sortBy);
    fetchPaginatedEmployees(1, employees.pageSize);
  };

  const handleSelectionChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const handleFilterChange = (event, newFilterType) => {
    if (newFilterType !== null) {
      setFilterType(newFilterType);
    }
  };

  const employmentTypeChip = (type) => {
    switch (type) {
      case 'Full':
        return <Chip label="Full-Time" color="primary" variant="outlined" />;
      case 'Part':
        return <Chip label="Part-Time" color="secondary" variant="outlined" />;
      case 'Freelance':
        return <Chip label="Freelance" color="info" variant="outlined" />;
      default:
        return <Chip label="Unknown" color="default" variant="outlined" />;
    }
  };

  const columns = [
    {
      field: 'checkbox',
      headerName: '',
      sortable: false,
      width: 50,
      sortable: false,
      renderCell: (params) => (
        <Checkbox
          checked={selectedRows.includes(params.row._id)}
          onChange={() => handleSelectionChange([params.row._id])}
        />
      ),
    },
    { field: 'firstName', headerName: 'First Name', flex: 1, sortable: false },
    { field: 'lastName', headerName: 'Last Name', flex: 1, sortable: false },
    { field: 'hireDate', headerName: 'Hire Date', flex: 1, sortable: false, valueFormatter: ({ value }) => value ? format(new Date(value), 'MM/dd/yyyy') : 'N/A' },
    {
      field: 'employmentType',
      headerName: 'Employment Type',
      flex: 1,
      sortable: false,
      renderCell: (params) => employmentTypeChip(params.value)
    },
    ...(showPersonalInfo
      ? [
        { field: 'phoneNumber', headerName: 'Phone', flex: 1, sortable: false },
        { field: 'email', headerName: 'Email', flex: 1, sortable: false },
        { field: 'address', headerName: 'Address', flex: 1, sortable: false, valueFormatter: ({ value }) => `${value.street}, ${value.city}, ${value.state} ${value.postalCode}` }
      ]
      : []),
    ...(filterType === 'terminated'
      ? [{ field: 'finalDate', headerName: 'Termination Date', flex: 1, sortable: false, valueFormatter: ({ value }) => value ? format(new Date(value), 'MM/dd/yyyy') : 'N/A' }]
      : [])
  ];

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Button
        onClick={() => handleEditEmployee(selectedRows[0])}
        startIcon={<EditIcon />}
        disabled={selectedRows.length !== 1}
      >
        Edit
      </Button>
      <Button
        onClick={handleDeleteEmployee}
        startIcon={<DeleteIcon />}
        disabled={selectedRows.length === 0}
      >
        Delete
      </Button>
    </GridToolbarContainer>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: 2, flexGrow: 1 }}>
          <DynamicSearchFilter
            defaultSort="firstName"
            onSearch={handleDynamicSearch}
            objectInterface={{ firstName: '', lastName: '', email: '', hireDate: new Date() }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <ToggleButtonGroup
          exclusive
          value={filterType}
          onChange={handleFilterChange}
          aria-label="Employment Filter"
        >
          <ToggleButton value="all">All</ToggleButton>
          <ToggleButton value="active">Active</ToggleButton>
          <ToggleButton value="terminated">Terminated</ToggleButton>
        </ToggleButtonGroup>
        <Box>
          <IconButton onClick={handleShowPersonalInfo} aria-label="show personal info">
            {showPersonalInfo ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
          <IconButton onClick={handleCreateEmployee} color="primary">
            <AddIcon />
          </IconButton>
        </Box>
      </Box>

      {employees.totalCount !== 0 ? (
        <>
          {isLargeScreen ? (
            <DataGridPro
              rows={employees.results}
              columns={columns}
              pageSize={employees.pageSize}
              rowCount={employees.totalCount}
              loading={loading}
              autoHeight
              disableSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              disableColumnSort
              onRowClick={(params, event) => {
                if (!event.target.closest('button') && !event.target.closest('.MuiDataGrid-checkboxInput')) {
                  handleViewEmployee(params.id);
                }
              }}
              onSelectionModelChange={handleSelectionChange}
              selectionModel={selectedRows}
              getRowId={(row) => row._id}
              components={{
                Toolbar: CustomToolbar
              }}
            />
          ) : (
            <List>
              {employees.results.map((employee) => (
                <ListItem
                  key={employee?._id}
                  button
                  onClick={() => handleViewEmployee(employee._id)}
                  sx={{
                    borderRadius: "8px",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)"
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Badge
                      badgeContent={employee.finalDate ? "Inactive" : "Active"}
                      color={employee.finalDate ? "error" : "success"}
                      overlap="circular"
                    >
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" fontWeight="bold">
                        {`${employee.firstName} ${employee.lastName}`}
                      </Typography>
                    }
                    secondary={
                      <>
                        {!showPersonalInfo ? (
                          <>
                          </>
                        ) : (
                          <>
                            <Typography variant="body2" color="textSecondary">
                              Email: {employee.email || 'N/A'}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Phone: {employee.phoneNumber || 'N/A'}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Address: {`${employee.address.street}, ${employee.address.city}, ${employee.address.state} ${employee.address.postalCode}`}
                            </Typography>
                          </>
                        )}
                        <Typography variant="body2" color="textSecondary">
                          Hire Date: {employee.hireDate ? format(new Date(employee.hireDate), 'MM/dd/yyyy') : 'N/A'}
                        </Typography>
                      </>
                    }
                  />
                  <Chip
                    label={employee.employmentType === "Full" ? "Full-Time" : employee.employmentType === "Part" ? "Part-Time" : "Freelance"}
                    color={employee.employmentType === "Full" ? "primary" : employee.employmentType === "Part" ? "secondary" : "info"}
                    variant="outlined"
                  />
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditEmployee(employee._id)}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          )}
          <TablePagination
            component="div"
            count={employees.totalCount}
            page={employees.currentPage > 0 ? employees.currentPage - 1 : 0}
            onPageChange={handlePageChange}
            rowsPerPage={employees.pageSize}
            onRowsPerPageChange={handleLimitChange}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={4}>
          <PersonIcon color="action" fontSize="large" />
          <Typography variant="h6" color="textSecondary">
            No employees available.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Once employees are added, they will appear here.
          </Typography>
        </Box>
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default Employees;
