import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Typography, Paper, Box, Button, Snackbar, TextField, Grid, Modal, IconButton, useTheme } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import CheckRegisterPDF from '../../../pdf/CheckRegisterPDF';
import { toJS } from 'mobx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CheckRegisterReport = observer(() => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [checkNo, setCheckNo] = useState('');
  const [datePaidFrom, setDatePaidFrom] = useState(null);
  const [datePaidTo, setDatePaidTo] = useState(null);

  const theme = useTheme();

  const fetchCheckRegister = async (checkNo, datePaidFrom, datePaidTo) => {
    setLoading(true);
    ReportsStore.fetchCheckRegister(checkNo, datePaidFrom, datePaidTo).then(() => {
      const report = toJS(ReportsStore.detailedReports.payableCheckRegister);
      console.log(report)
      setReport(report);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch check register report', severity: 'error' });
      setLoading(false);
    });
  }

  const generateReports = () => {
    fetchCheckRegister(checkNo, datePaidFrom, datePaidTo);
  }

  const handleClose = () => {
    setViewPdf(false);
  }

  const columns = [
    { field: 'payablesId', headerName: 'ID', flex: 1 },
    { field: 'invoiceAmount', headerName: 'Invoice Amount', flex: 1 },
    { field: 'paidAmount', headerName: 'Paid Amount', flex: 1 },
    { field: 'datePaid', headerName: 'Date Paid', flex: 1, valueFormatter: ({ value }) => dayjs(value).format('MM/DD/YYYY') },
    { field: 'dueDate', headerName: 'Due Date', flex: 1, valueFormatter: ({ value }) => dayjs(value).format('MM/DD/YYYY') },
    { field: 'invoiceDate', headerName: 'Invoice Date', flex: 1, valueFormatter: ({ value }) => dayjs(value).format('MM/DD/YYYY') },
    { field: 'invoiceNo', headerName: 'Invoice No', flex: 1 },
    { field: 'vendor', headerName: 'Vendor', flex: 1, valueGetter: ({ row }) => row.vendor.vendorName },
    { field: 'void', headerName: 'Void', flex: 1, type: 'boolean' },
  ];

  return (
    <Box sx={{ height: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: 2 }}>
            <Typography variant="h4">Check Register Report</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 3, maxWidth: '800px', mx: 'auto' }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                label="Check Number"
                value={checkNo}
                onChange={(event) => setCheckNo(event.target.value)}
              />
              <DatePicker
                label="Date Paid From"
                value={datePaidFrom}
                onChange={(date) => setDatePaidFrom(date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <DatePicker
                label="Date Paid To"
                value={datePaidTo}
                onChange={(date) => setDatePaidTo(date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
              <Button
                onClick={() => generateReports()}
                variant="contained"
                sx={{ flex: 1, fontWeight: 'bold' }}
              >
                Generate Report
              </Button>
              {report && (
                <Button
                  onClick={() => setViewPdf(true)}
                  variant="outlined"
                  sx={{ flex: 1 }}
                >
                  View as PDF
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 4 }}>
          {viewPdf ? (
            <Modal
              open={viewPdf}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                <IconButton
                  onClick={handleClose}
                  sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                >
                  <CloseIcon />
                </IconButton>
                <PDFViewer width={'100%'} height={'100%'}>
                  <CheckRegisterPDF report={report} />
                </PDFViewer>
              </Paper>
            </Modal>
          ) : (
            <DataGridPro
              rows={report?.result || []}
              columns={columns}
              autoHeight
              loading={loading}
              getRowId={(row) => row.payablesId}
            />
          )}
        </Box>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </LocalizationProvider>
    </Box>
  );
});

export default CheckRegisterReport;
